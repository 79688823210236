import { createGlobalStyle } from "styled-components"

const Typography = createGlobalStyle`

  html {
    font-size: 10px;
  }
  
  body {
    font-family: 'Secular One', sans-serif;
    font-size: 2rem;
    line-height: 1.35em;

    @media (max-width: 576px) {
      font-size: 1.7rem;
    }
  }

  h1 {
    font-size: 5rem;

    @media (max-width: 768px) {
      font-size: 3.5rem;
      }
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2.3rem;
    line-height: 1.2em;
  }

  h4 {
    line-height: 1.3em;
  }

  p {
    &.small {
      font-size: 15px;
    }

  }

  a {
    color: white;
    text-decoration: none;

    &:hover {
      color: white;
      text-decoration: none;
    }
  }
  
`

export default Typography
