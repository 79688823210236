import React, { useState } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import Headroom from "react-headroom"

const StyledHeader = styled.header`
  width: 100%;
  padding: 15px 0;
  background-color: var(--bg-blue);
  z-index: 999;

  @media (max-width: 768px) {
    padding-bottom: 10px;
  }

  .navbar {
    padding: 0;
  }

  .navbar-brand,
  .navbar-nav {
    font-size: 30px;
    line-height: 1.3em;

    @media (max-width: 768px) {
      font-size: 2.3rem;
    }
  }

  .navbar-collapse {
    @media (max-width: 768px) {
      border-top: 2px solid white;
      border-bottom: 2px solid white;
    }
  }

  .navbar-nav {
    a {
      display: block;
      position: relative;

      @media (min-width: 768px) {
        &:not(:last-child) {
          margin-right: 1.5em;
        }
      }

      @media (max-width: 768px) {
        &:not(:last-child) {
          margin-bottom: 0.25em;
        }
      }

      &[aria-current="page"] {
        &:after {
          content: "";
          position: absolute;
          display: block;
          left: 0;
          right: 0;
          bottom: 0;
          height: 4px;
          background-color: white;

          @media (max-width: 768px) {
            height: 3px;
          }
        }
      }

      &:hover {
        &:after {
          content: "";
          position: absolute;
          display: block;
          left: 0;
          right: 0;
          bottom: 0;
          height: 4px;
          background-color: white;

          @media (max-width: 768px) {
            height: 3px;
          }
        }
      }
    }
  }
`

export default function Header({ location }) {
  const [expanded, setExpanded] = useState(false)

  return (
    <Headroom disableInlineStyles>
      <StyledHeader>
        <Helmet>
          <link
            rel="apple-touch-icon"
            sizes="57x57"
            href="/fav/apple-icon-57x57.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="60x60"
            href="/fav/apple-icon-60x60.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="72x72"
            href="/fav/apple-icon-72x72.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="76x76"
            href="/fav/apple-icon-76x76.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="114x114"
            href="/fav/apple-icon-114x114.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="120x120"
            href="/fav/apple-icon-120x120.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="144x144"
            href="/fav/apple-icon-144x144.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="152x152"
            href="/fav/apple-icon-152x152.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/fav/apple-icon-180x180.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="192x192"
            href="/fav/android-icon-192x192.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/fav/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="96x96"
            href="/fav/favicon-96x96.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/fav/favicon-16x16.png"
          />
          <link rel="manifest" href="/fav/manifest.json" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta
            name="msapplication-TileImage"
            content="/fav/ms-icon-144x144.png"
          />

          <meta name="theme-color" content="#ffffff" />
          <meta
            name="description"
            content="Konzeption, Gestaltung und Umsetzung von Webseiten und Webapps in Zürich."
          />
        </Helmet>

        <Navbar expand="md" variant="dark" expanded={expanded}>
          <Container className="align-items-start align-items-md-center">
            <Navbar.Brand>
              <Link to="/" onClick={() => setExpanded(false)}>
                Thierry Seiler <br /> Webseiten & Webapps
              </Link>
            </Navbar.Brand>

            <button
              className={`hamburger hamburger--squeeze d-block d-md-none ${
                expanded && "is-active"
              }`}
              type="button"
              onClick={() => setExpanded(expanded ? false : "expanded")}
            >
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>

            <Navbar.Collapse
              id="basic-navbar-nav"
              className="mt-3 py-3 mt-md-0 py-md-0"
            >
              <Nav className="ms-auto align-items-center">
                <Link to="/aktuell" onClick={() => setExpanded(false)}>
                  Aktuell
                </Link>

                <Link to="/angebot" onClick={() => setExpanded(false)}>
                  Angebot
                </Link>

                <Link to="/kontakt" onClick={() => setExpanded(false)}>
                  Kontakt
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </StyledHeader>
    </Headroom>
  )
}
